<template>
  <div class="leftInfo">
    <div class="usdt">
      usdt
    </div>
    <div class="scroll">
      <div
        class="item"
        v-for="(item, index) in productNowInfo"
        :key="index"
        :style="{
          color:
            item.BasicPrice - item.Price > 0
              ? basicConfig.FallColour
              : basicConfig.RiseColour
        }"
        @click="handleClick(index)"
      >
        <span> {{ item.EnglishName }}</span>
        <span>{{ item.Price }}</span>
        <span>{{ getRole(item) }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    productNowInfo: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  methods: {
    getRole(item) {
      const Role = (
        ((item.Price - item.BasicPrice) / item.Price) *
        100
      ).toFixed(2)
      return Role > 0 ? `+${Role}` : Role
    },
    handleClick(index) {
      this.$emit('change', index)
    }
  }
}
</script>

<style lang="less" scoped>
.leftInfo {
  height: 100%;
  background: #202833;
  font-size: 13px;
  .title {
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #555359;
  }
  .usdt {
    color: #ffffff;
    padding: 10px 15px;
    font-size: 18px;
    text-align: center;
    border-bottom: 1px solid #5d86d8;
  }
  .scroll {
    overflow-y: auto;
    height: calc(100% - 88px);
    padding-bottom: 50px;
    .item {
      border-bottom: 1px solid #555359;
      padding: 0 10px;
      height: 60px;
      display: flex;
      align-items: center;
      span {
        flex: 1;
        text-align: center;
        &:nth-of-type(1) {
          color: #728daf;
          text-align: left;
        }
        &:nth-of-type(3) {
          text-align: right;
        }
      }
    }
  }
}
</style>
