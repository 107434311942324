var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lever-deal"},[_c('div',{staticClass:"deal"},[_c('div',{staticClass:"deal-center"},[_c('div',{staticClass:"mb-24"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"down-menu",on:{"click":function($event){_vm.show = true}}},[_c('span',{staticClass:"coin-name"},[_vm._v(_vm._s(_vm.showProductName())+"/USDT")]),_c('div',{staticClass:"svg-icon mt-1 icon-ic_down"})]),_c('div',{staticClass:"tradata-right"},[_c('ul',{staticClass:"header-ul",style:({
                  color:
                    _vm.productNowInfo[_vm.activeIndex].BasicPrice -
                      _vm.productNowInfo[_vm.activeIndex].Price >
                    0
                      ? _vm.basicConfig.FallColour
                      : _vm.basicConfig.RiseColour
                })},[_c('li',{staticClass:"family-d"},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Price)+" ")])]),_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('pcrapidly').dd))]),_c('div',{staticClass:"number",style:({
                      color:
                        _vm.productNowInfo[_vm.activeIndex].BasicPrice -
                          _vm.productNowInfo[_vm.activeIndex].Price >
                        0
                          ? _vm.basicConfig.FallColour
                          : _vm.basicConfig.RiseColour
                    })},[_vm._v(" "+_vm._s(_vm.getRole())+"% ")])]),_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('contract').gao))]),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].High)+" ")])]),_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('contract').di))]),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Low)+" ")])]),_c('li',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('contract').liang))]),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Amount)+" ")])])])])])]),_c('div',{staticClass:"data-show"},[_c('div',{staticClass:"k-chart"},[_c('TradingView',{ref:"tra",attrs:{"TvCode":_vm.select_tv_code,"Height":"540px"}})],1)])]),_c('div',{staticClass:"bottom-tab"},[_c('Trust',{ref:"trust",attrs:{"productId":_vm.productNowInfo[_vm.activeIndex].Id}})],1)]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"data"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('trade').jiage))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('trade').shuliang))])]),_vm._l((_vm.topData),function(item,iIndex){return _c('div',{key:iIndex,staticClass:"item top"},[_c('span',{style:({ color: _vm.getColor(1) })},[_vm._v(_vm._s(item.price))]),_c('span',[_vm._v(_vm._s(item.num))])])}),_c('div',{staticClass:"price",style:({
              color:
                _vm.mode === 1 ? _vm.basicConfig.FallColour : _vm.basicConfig.RiseColour
            })},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Price)+" ")]),_vm._l((_vm.bottomData),function(item,bIndex){return _c('div',{key:`b${bIndex}`,staticClass:"item bottom"},[_c('span',{style:({ color: _vm.getColor(2) })},[_vm._v(_vm._s(item.price))]),_c('span',[_vm._v(_vm._s(item.num))])])})],2),_c('div',{staticClass:"order-box"},[_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tab-item",class:{ 'quote-active': _vm.activeSelect === 0 },on:{"click":function($event){return _vm.handleSelect(0)}}},[_vm._v(" "+_vm._s(_vm.$t('pccontract').xj)+" ")]),_c('div',{staticClass:"tab-item",class:{ 'quote-active': _vm.activeSelect === 1 },on:{"click":function($event){return _vm.handleSelect(1)}}},[_vm._v(" "+_vm._s(_vm.$t('pccontract').ss)+" ")])]),_c('div',{staticClass:"buy"},[_c('div',{staticClass:"btn",style:({
                background: _vm.mode === 0 ? _vm.basicConfig.RiseColour : null,
                color: _vm.mode === 0 ? '#fff' : null
              }),on:{"click":function($event){return _vm.changeMode(0)}}},[_vm._v(" "+_vm._s(_vm.$t('trade').Buy)+" ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"btn",style:({
                background: _vm.mode === 1 ? _vm.basicConfig.FallColour : null,
                color: _vm.mode === 1 ? '#fff' : null
              }),on:{"click":function($event){return _vm.changeMode(1)}}},[_vm._v(" "+_vm._s(_vm.$t('trade').Sell)+" ")])]),_c('div',{staticClass:"box"},[(_vm.activeSelect === 0)?_c('div',{staticClass:"input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Price),expression:"form.Price"}],attrs:{"placeholder":_vm.$t('trade').jiage,"type":"number"},domProps:{"value":(_vm.form.Price)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "Price", $event.target.value)}}})]):_c('div',{staticClass:"input mark",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('trade').zyjg)+" ")]),_c('div',{staticClass:"jiaoyi"},[_c('span',[_vm._v(_vm._s(_vm.$t('trade').shuliang)+":")]),_c('div',{staticClass:"input num"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Num),expression:"form.Num"}],attrs:{"type":"number","placeholder":_vm.$t('trade').shuliang},domProps:{"value":(_vm.form.Num)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "Num", $event.target.value)}}}),_c('div',{staticClass:"num_right"},[_vm._v(" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Name)+" ")])])]),_c('div',{staticClass:"validate"},[_vm._v(" "+_vm._s(_vm.$t('trade').keyong)+"："+_vm._s(_vm.LegalAmountInfo.USDT)+" USDT ")]),_c('div',{staticClass:"select-box"},[_c('van-slider',{attrs:{"step":25,"bar-height":"7px"},on:{"change":_vm.precentChange},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"custom-button"},[_vm._v(_vm._s(_vm.selectPrecent)+"%")])]},proxy:true}]),model:{value:(_vm.selectPrecent),callback:function ($$v) {_vm.selectPrecent=$$v},expression:"selectPrecent"}})],1),_c('div',{staticClass:"validate"},[_vm._v(" "+_vm._s(_vm.$t('trade').jiaoyie)+"："+_vm._s(_vm.allAmount)+" USDT ")]),_c('div',{staticClass:"btn",style:({
                background:
                  _vm.mode === 0 ? _vm.basicConfig.RiseColour : _vm.basicConfig.FallColour
              }),on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.mode === 0 ? _vm.$t('trade').Buy : _vm.$t('trade').Sell)+" "+_vm._s(_vm.productNowInfo[_vm.activeIndex].Name)+" ")])])])])])]),_c('van-popup',{style:({ width: '262px', height: '100vh' }),attrs:{"position":"left"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('PopupInfo',{attrs:{"productNowInfo":_vm.productNowInfo},on:{"change":_vm.changeProduct}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }