<template>
  <div class="lever-deal">
    <div class="deal">
      <!-- 左侧 -->
      <div class="deal-center">
        <div class="mb-24">
          <!-- 头部信息展示 -->
          <div class="info">
            <div class="info-content">
              <!-- 下拉框 -->
              <div class="down-menu" @click="show = true">
                <span class="coin-name">{{ showProductName() }}/USDT</span>
                <div class="svg-icon mt-1 icon-ic_down"></div>
              </div>
              <div class="tradata-right">
                <ul
                  class="header-ul"
                  :style="{
                    color:
                      productNowInfo[activeIndex].BasicPrice -
                        productNowInfo[activeIndex].Price >
                      0
                        ? basicConfig.FallColour
                        : basicConfig.RiseColour
                  }"
                >
                  <li class="family-d">
                    <div class="text">
                      {{ productNowInfo[activeIndex].Price }}
                    </div>
                  </li>
                  <li>
                    <div class="title">{{ $t('pcrapidly').dd }}</div>
                    <div
                      class="number"
                      :style="{
                        color:
                          productNowInfo[activeIndex].BasicPrice -
                            productNowInfo[activeIndex].Price >
                          0
                            ? basicConfig.FallColour
                            : basicConfig.RiseColour
                      }"
                    >
                      {{ getRole() }}%
                    </div>
                  </li>
                  <li>
                    <div class="title">{{ $t('contract').gao }}</div>
                    <div class="number">
                      {{ productNowInfo[activeIndex].High }}
                    </div>
                  </li>
                  <li>
                    <div class="title">{{ $t('contract').di }}</div>
                    <div class="number">
                      {{ productNowInfo[activeIndex].Low }}
                    </div>
                  </li>
                  <li>
                    <div class="title">{{ $t('contract').liang }}</div>
                    <div class="number">
                      {{ productNowInfo[activeIndex].Amount }}
                    </div>
                  </li>
                  <!-- <li>
                    <div class="title">24H成交额</div>
                    <div class="number">$757.98M</div>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
          <!-- k线图 -->
          <div class="data-show">
            <div class="k-chart">
              <TradingView
                :TvCode="select_tv_code"
                Height="540px"
                ref="tra"
              ></TradingView>
            </div>
          </div>
        </div>
        <!-- 订单 -->
        <div class="bottom-tab">
          <Trust :productId="productNowInfo[activeIndex].Id" ref="trust" />
        </div>
      </div>
      <!-- 右侧 -->
      <div class="container">
        <div class="top">
          <div class="data">
            <div class="title">
              <span> {{ $t('trade').jiage }}</span>
              <span> {{ $t('trade').shuliang }}</span>
            </div>
            <div
              class="item top"
              v-for="(item, iIndex) in topData"
              :key="iIndex"
            >
              <span :style="{ color: getColor(1) }">{{ item.price }}</span>
              <span>{{ item.num }}</span>
            </div>
            <div
              class="price"
              :style="{
                color:
                  mode === 1 ? basicConfig.FallColour : basicConfig.RiseColour
              }"
            >
              {{ productNowInfo[activeIndex].Price }}
            </div>
            <div
              class="item bottom"
              v-for="(item, bIndex) in bottomData"
              :key="`b${bIndex}`"
            >
              <span :style="{ color: getColor(2) }">{{ item.price }}</span>
              <span>{{ item.num }}</span>
            </div>
          </div>
          <div class="order-box">
            <div class="tab">
              <div
                class="tab-item"
                :class="{ 'quote-active': activeSelect === 0 }"
                @click="handleSelect(0)"
              >
                {{ $t('pccontract').xj }}
              </div>
              <div
                class="tab-item"
                @click="handleSelect(1)"
                :class="{ 'quote-active': activeSelect === 1 }"
              >
                {{ $t('pccontract').ss }}
              </div>
            </div>
            <div class="buy">
              <div
                class="btn"
                @click="changeMode(0)"
                :style="{
                  background: mode === 0 ? basicConfig.RiseColour : null,
                  color: mode === 0 ? '#fff' : null
                }"
              >
                {{ $t('trade').Buy }}
              </div>
              <div class="line"></div>
              <div
                class="btn"
                @click="changeMode(1)"
                :style="{
                  background: mode === 1 ? basicConfig.FallColour : null,
                  color: mode === 1 ? '#fff' : null
                }"
              >
                {{ $t('trade').Sell }}
              </div>
            </div>
            <div class="box">
              <div class="input" v-if="activeSelect === 0">
                <input
                  v-model="form.Price"
                  :placeholder="$t('trade').jiage"
                  type="number"
                />
              </div>
              <div class="input mark" v-else style="width: 100%">
                {{ $t('trade').zyjg }}
              </div>
              <div class="jiaoyi">
                <span>{{ $t('trade').shuliang }}:</span>
                <div class="input num">
                  <input
                    v-model="form.Num"
                    type="number"
                    :placeholder="$t('trade').shuliang"
                  />
                  <div class="num_right">
                    {{ productNowInfo[activeIndex].Name }}
                  </div>
                </div>
              </div>
              <div class="validate">
                {{ $t('trade').keyong }}：{{ LegalAmountInfo.USDT }} USDT
              </div>
              <div class="select-box">
                <van-slider
                  v-model="selectPrecent"
                  @change="precentChange"
                  :step="25"
                  bar-height="7px"
                >
                  <template #button>
                    <div class="custom-button">{{ selectPrecent }}%</div>
                  </template>
                </van-slider>
              </div>
              <div class="validate">
                {{ $t('trade').jiaoyie }}：{{ allAmount }} USDT
              </div>
              <div
                class="btn"
                @click="submit"
                :style="{
                  background:
                    mode === 0 ? basicConfig.RiseColour : basicConfig.FallColour
                }"
              >
                {{ mode === 0 ? $t('trade').Buy : $t('trade').Sell }}
                {{ productNowInfo[activeIndex].Name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup
      v-model="show"
      position="left"
      :style="{ width: '262px', height: '100vh' }"
    >
      <PopupInfo :productNowInfo="productNowInfo" @change="changeProduct" />
    </van-popup>
  </div>
</template>

<script>
import TradingView from '@/components/TradingView'
import productApi from '@/api/product'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import Trust from './components/trust'
import PopupInfo from './components/popupInfo'
const nodata = [
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  }
]
export default {
  name: 'CurrencyMobileNewIndex',
  components: { TradingView, Trust, PopupInfo },
  data() {
    return {
      select_tv_code: '',
      tabActiveName: 'position',
      activeIndex: 0,
      dataMoeActiveIndex: 2,
      Kdata: [],
      lastPrice: null,
      productList: [],
      productNowInfo: [
        {
          BasicPrice: 0,
          Price: 0,
          High: 0,
          Low: 0,
          Amount: 0
        }
      ],
      dataModeList: [],
      form: {
        ProductId: null,
        OrderType: null,
        BuyDirection: null,
        Num: null,
        Multiple: null,
        PriceProportion: null,
        Price: null
      },
      selectPrecent: 25,
      activeSelect: 0,
      mode: 0,
      showMultipleSelect: false,
      multipleSelect: 0,
      multipleActions: [],
      options: [
        {
          value: 0,
          label: 'x10'
        },
        {
          value: 1,
          label: 'x20'
        },
        {
          value: 2,
          label: 'x50'
        }
      ],
      ContractRatio: [],
      LegalAmountInfo: [],
      topData: nodata,
      bottomData: nodata,
      buyForm: {
        name: ''
      },
      show: false,
      Ldata: [],
      split: []
    }
  },
  watch: {
    mode() {
      this.form.Price = this.productNowInfo[this.activeIndex].Price
      this.form.Num = null
    },
    activeSelect() {
      this.form.Price = this.productNowInfo[this.activeIndex].Price
      this.form.Num = null
    },
    productId(Val) {
      this.form.productId = Val
    }
  },
  mounted() {
    this.firstEnter = false
    this.change()
    this.getList()
    this.getUserInfo()
  },

  methods: {
    loadData() {
      console.log('执行')
      this.$refs.trust.reset()
    },
    async submit() {
      if (!this.form.Price) {
        this.$toast(this.$t('trade').tips)
        return
      }
      if (!this.form.Num) {
        this.$toast(this.$t('trade').tips1)
        return
      }
      this.form.OrderType = this.activeSelect ? 'Present' : 'Limit'
      this.form.BuyDirection = this.mode ? 'Sell' : 'Buy'
      this.form.ProductId = this.productNowInfo[this.activeIndex].Id
      this.form.Price = Number(this.form.Price)
      this.form.Num = Number(this.form.Num)
      await productApi.createCoinOrder(this.form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.getUserInfo()
        this.$refs.trust.reset()
      }, 500)
    },
    async getUserInfo() {
      const res = await userApi.userInfo()
      this.LegalAmountInfo = res.LegalAmountInfo
    },
    getRole() {
      const Role = (
        ((this.productNowInfo[this.activeIndex].Price -
          this.productNowInfo[this.activeIndex].BasicPrice) /
          this.productNowInfo[this.activeIndex].Price) *
        100
      ).toFixed(2)
      return Role > 0 ? `+${Role}` : Role
    },
    changeMode(index) {
      this.mode = index
    },
    handleSelect(value) {
      this.activeSelect = value
    },
    precentChange() {
      if (this.mode) {
        this.form.Num = (this.selectPrecent / 100) * this.getCoinBalance()
      } else {
        this.form.Num = (
          ((this.selectPrecent / 100) * this.LegalAmountInfo.USDT) /
          this.productNowInfo[this.activeIndex].Price
        ).toFixed(3)
      }
    },
    getColor(index) {
      if (index === 1) {
        return this.basicConfig.RiseColour
      } else {
        return this.basicConfig.FallColour
      }
    },
    // 展示币种信息
    showProductName() {
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === this.ProductId) {
          name = item.Name
          // if (this.lang === 'zh') {
          //   name = item.Name
          // } else if (this.lang === 'en') {
          //   name = item.EnglishName
          // } else {
          //   name = item.TraditionalName
          // }
          return
        }
      })
      return name
    },
    change() {
      let product = this.$t('contract')
      this.dataModeList = [
        product.Trend,
        '3' + product.smallMin,
        '5' + product.smallMin,
        '15' + product.smallMin,
        '1' + product.hour,
        '1' + product.dayMin
      ]
    },
    async getList() {
      this.productList = await productApi.list()
      const ProductId = this.$route.params.id
      if (ProductId) {
        this.ProductId = ProductId
        sessionStorage.setItem('product', ProductId)
      } else {
        const ProductId1 = sessionStorage.getItem('product')
        this.ProductId = ProductId1
          ? Number(ProductId1)
          : this.productList[0].Id
      }

      this.productNowInfo = this.productList
      this.firstSetTabProduct()
      this.signalRStart()
    },
    // 获得购买选项
    getbuyModeList(index) {
      this.ProductInfo = this.productList[index]
      this.buyModeList = this.productList[index].Proportion
    },
    firstSetTabProduct() {
      if (this.firstEnter) {
        // 说明不是第一次进入
        return
      }
      this.productList.forEach((item, index) => {
        if (item.Id === this.ProductId) {
          this.firstEnter = true
          this.activeIndex = index
          // 获得购买选项
          this.getbuyModeList(index)
          // this.getKData(2)
          this.select_tv_code = item.TraditionalName
          this.$refs.tra.tradingview(this.select_tv_code)
          return
        }
      })
      this.form.Price = this.productNowInfo[this.activeIndex].Price
    },
    // 选择产品
    changeProduct(index) {
      this.show = false
      this.activeIndex = index
      this.ProductId = this.productList[index].Id
      sessionStorage.setItem('product', this.ProductId)
      this.dataMoeActiveIndex = 2
      this.getbuyModeList(index)
      // this.getKData(2)
      this.select_tv_code = this.productList[index].TraditionalName
      this.$refs.tra.tradingview(this.select_tv_code)
      this.Ldata = []
      this.split = []
      this.signalRStart()
      setTimeout(() => {
        this.$refs.trust.reset()
      }, 10)
    },
    // 选择分时图，1M,3M,5M,15M
    changedataMode(index) {
      this.dataMoeActiveIndex = index
      this.getKData(index)
    },
    async getSecondData(data) {
      let productNowInfo = data
      let productList = [...this.productList]
      productNowInfo.forEach((item) => {
        productList.some((pItem) => {
          if (pItem.Id === item.ProductId) {
            pItem.Price = item.Price
            pItem.Rise = item.Rise
            pItem.High = item.High
            pItem.Low = item.Low
            pItem.Amount = item.Amount
            return true
          }
        })
      })

      this.productNowInfo = productList
      if (!this.dataMoeActiveIndex) {
        data.forEach((item) => {
          if (item.ProductId === this.ProductId) {
            this.Ldata.push(item.Price)
            this.split.push('')
            if (this.Ldata.length > 100) {
              this.Ldata.shift()
              this.split.shift()
            }
          }
        })
      }
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke(
            'SendMsg',
            this.productNowInfo[this.activeIndex].Name
          )
        }
      }, 100)
      connection.on('secondPrices', function (res) {
        that.getSecondData(res)
      })
      connection.on('Depth', function (res) {
        that.getDepthData(res)
      })
    },
    getDepthData(res) {
      let that = this
      const res1 = JSON.parse(res)
      if (res1.Name === that.productNowInfo[that.activeIndex].Name) {
        const arr = res1['bids']
        const arr1 = res1['asks']
        let bids = []
        let asks = []
        if (arr != null) {
          arr.forEach((iitem) => {
            bids.push({
              price: iitem[0],
              num: iitem[1]
            })
          })
        }
        if (arr1 != null) {
          arr1.forEach((iitem) => {
            asks.push({
              price: iitem[0],
              num: iitem[1]
            })
          })
        }
        if (asks.length > 0) {
          this.topData = []
          for (let i = 0; i < 7; i++) {
            this.topData.push(asks[i])
          }
        }
        if (bids.length > 0) {
          this.bottomData = []
          for (let j = 0; j < 7; j++) {
            this.bottomData.push(bids[j])
          }
        }
        return
      }
    },
    // 获取数据
    async getKData(mode) {
      let res = []
      console.log(mode)
      switch (mode) {
        case 1:
          res = await productApi.get3M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 2:
          res = await productApi.get5M(this.ProductId)

          this.lastPrice = res[res.length - 1]
          console.log(this.lastPrice)
          break
        case 3:
          res = await productApi.get15M(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 4:
          res = await productApi.get1Hour(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        case 5:
          res = await productApi.getOneDay(this.ProductId)
          this.lastPrice = res[res.length - 1]
          break
        default:
          this.Ldata = []
          this.getLata()
          this.split = [...Array(100)].map(() => '')
      }
      this.Kdata = res
    },
    async getLata() {
      const result = await productApi.getOneSecondPrices(this.ProductId)
      result.forEach((item) => {
        this.Ldata.push(item.Price)
      })
    }
  },
  computed: {
    lang() {
      this.change()
      return this.$store.getters.lang
    },
    ...mapGetters(['connection', 'basicConfig']),
    allAmount() {
      if (this.activeSelect === 1) {
        const num = String(
          this.productNowInfo[this.activeIndex].Price * this.form.Num
        )
        if (num.indexOf('.') !== -1) {
          const arr = num.split('.')
          if (arr[1] && arr[1].length > 6) {
            return `${arr[0]}.${arr[1].substr(0, 6)}`
          } else {
            return num
          }
        } else {
          return num
        }
      } else {
        const { Price, Num } = { ...this.form }
        if (Price && Num) {
          const num = String(Price * Num)
          if (num.indexOf('.') !== -1) {
            const arr = num.split('.')
            if (arr[1] && arr[1].length > 6) {
              return `${arr[0]}.${arr[1].substr(0, 6)}`
            }
          }
          return num
        } else {
          return '0'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.lever-deal {
  background-color: #2a2a2a !important;
  padding: 20px 0;
  .deal {
    margin: 0 auto;
    width: 90.77%;
    justify-content: space-between;
    display: flex;
    .deal-center {
      width: 80.98%;
      min-height: 775px;
      border-radius: 8px;
      .mb-24 {
        margin-bottom: 24px;
        .info {
          background-color: #1e1e1e !important;
          height: 60px;
          padding: 0 16px;
          margin-bottom: 16px;
          border-radius: 8px;
          align-items: center;
          justify-content: space-between;
          display: flex;
          .info-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .down-menu {
              margin-right: 10px;
              color: #f6f6f6;
              position: relative;
              cursor: pointer;
              font-weight: 700;
              font-size: 26px;
              font-family: DINPro;
              display: flex;
              align-items: center;
              .coin-name {
                font-family: DINPro;
                margin-right: 10px;
              }
              .icon-ic_down {
                background-image: url('../../assets/pc/svg/download.png');
              }
              .svg-icon {
                width: 1em;
                height: 1em;
                vertical-align: -0.15em;
                fill: currentColor;
                overflow: hidden;
              }
            }
            .header-ul {
              justify-content: space-between;
              display: flex;
              li {
                min-width: 120px;
                text-align: left;
                &:nth-child(n + 1) {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                }
                &,
                .family-d {
                  font-family: DINPro;
                }
                .text {
                  line-height: 34px;
                  color: #da5252;
                  margin-bottom: 2px;
                  font-weight: 700;
                  font-size: 18px;
                }
                .title {
                  color: #bdbdbd;
                  margin-bottom: 4px;
                  font-size: 10px;
                }
                .number {
                  color: #f6f6f6;
                  font-weight: 700;
                  font-size: 14px;
                  font-family: DINPro;
                }
                .red {
                  color: #da5252;
                }
              }
            }
            .tradata-right {
              width: 580px;
              padding-right: 16px;
            }
          }
        }
        .data-show {
          display: flex;
          justify-content: space-between;

          .k-chart {
            position: relative;
            width: 100%;
            height: 540px;
            border-radius: 8px;
            .tool-box {
              width: 100%;
              height: 38px;
              // background-color: pink;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #131722;
              .box {
                color: #999999;
                display: flex;
                align-items: center;
                padding: 0 30px;
                height: 100%;
                cursor: pointer;
              }
              .active {
                color: #218dbe;
                border-bottom: 0.01rem solid #218dbe;
              }
            }
            .echars {
              height: 100%;
            }
          }
          // .order-box {
          //   background-color: #1e1e1e !important;
          //   width: 17.85%;
          //   min-height: 530px;
          //   padding: 10px;
          //   box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
          //   border-radius: 4px;
          //   .sell-text {
          //     color: #f6f6f6;
          //     min-height: 23px;
          //     width: 28px;
          //     margin-right: 24px;
          //     position: relative;
          //     white-space: nowrap;
          //     text-align: left;
          //     margin-bottom: 12px;
          //     font-weight: 700;
          //     &::after {
          //       content: '';
          //       width: 100%;
          //       height: 2px;
          //       bottom: 0;
          //       left: 0;
          //       border-radius: 2px;
          //       background: #3d67fa;
          //       position: absolute;
          //     }
          //   }
          //   .table-head {
          //     display: flex;
          //     justify-content: space-between;
          //     align-items: center;
          //     color: #bdbdbd;
          //     margin-bottom: 11px;
          //     font-size: 10px;
          //   }
          //   .table {
          //     margin-bottom: 30px;
          //     font-size: 10px;
          //     .row {
          //       line-height: 18px;
          //       font-weight: 600;

          //       display: flex;
          //       justify-content: space-between;
          //       .right {
          //         color: #bdbdbd;
          //       }
          //     }
          //     .red {
          //       color: #da5252;
          //     }
          //     .green {
          //       color: #60ad76;
          //     }
          //   }
          // }
        }
      }
      .bottom-tab {
        width: 100%;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
        margin: 0 auto;
        border-radius: 8px;
        background-color: #1e1e1e !important;

        .el-tabs {
          // border-bottom: 1px solid #676767;
          & /deep/.el-tabs__nav-wrap::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #676767;
            z-index: 1;
          }
          & /deep/ .el-tabs__nav {
            height: 40px;
            padding-left: 28px;
            .el-tabs__item {
              line-height: 40px;
              color: #bdbdbd;
              font-weight: 600;
              font-size: 14px;
            }
            .is-active {
              color: #fff;
            }
            .el-tabs__active-bar {
              left: 28px;
            }
          }
          & /deep/ .el-tabs__content {
            padding: 28px;
            .el-table {
              tr,
              th {
                background-color: #1e1e1e;
              }
            }
          }
        }
      }
    }
  }
}
.container {
  background-color: #1e1e1e !important;
  width: 17.58%;
  padding: 10px 10px 0;
  border-radius: 8px;
  color: #f2f5ff;
  .popup {
    ::v-deep .van-picker {
      background: #020406;
      .van-picker__toolbar {
        background: #010203;
      }
    }
    ::v-deep .van-picker-column__item {
      color: #fff !important;
    }
    ::v-deep .van-picker-column__item--selected {
      font-size: 20px;
      font-weight: bold;
    }
    ::v-deep .van-picker__mask {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .top {
    padding: 0.12rem 0 0 0;
    // display: flex;
    .tab {
      color: #bdbdbd;
      border-color: #676767 !important;
      border-bottom: 1px solid;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tab-item {
        position: relative;
        cursor: pointer;
        line-height: 30px;
      }
      .quote-active::after {
        content: '';
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        background: #3d67fa;
        position: absolute;
      }
    }
    .order-box {
      width: 100%;
      .buy {
        display: flex;
        background: #1a273f;
        position: relative;
        margin-bottom: 12px;
         user-select: none; 
        .line {
          width: 15px;
          height: 55px;
          transform: rotate(25deg);
          position: absolute;
          left: calc(50% - 9px);
          top: -10px;
          background: #1e1e1e;
        }
        .btn {
          flex: 1;
          height: 33px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
        }
      }
      .input-box {
        display: flex;
        width: 100%;
        height: 34px;
        border: 0.01rem solid #42526a;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;

        .el-select {
          & /deep/ .el-input__inner {
            color: #f6f6f6;
            border: none;
            background: none !important;
            text-align: left;
            padding: 0;
            height: 100%;
          }
        }
        .el-select {
          width: 60%;
          height: 100%;

          & /deep/ .el-input {
            height: 100%;
            display: flex;
            align-items: center;
          }
          & /deep/ .el-input__inner {
            height: 100%;
            background-color: transparent;
            border: none;
          }
          & /deep/ .el-input__icon {
            line-height: unset;
          }
        }
        .el-input {
          height: 100%;
          background: none;
        }
      }
      .buy-mode {
        padding: 14px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #ffffff;
        img {
          width: 15px;
          height: 15px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
        }
        .item {
          width: 130px;
          height: 30px;
          padding-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #4d5568;
          position: relative;
        }
        .right {
          width: 63px;
        }
      }
      .box {
        p {
          padding-top: 10px;
        }
        .jiaoyi {
          display: flex;
          align-items: center;
          span {
            margin: 15px 5px 0 0;
            font-size: 13px;
          }
        }
        .input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 38px;
          flex: 1;
          border: 1px solid #42526a;
          margin-top: 15px;
          input {
            padding: 0 10px;
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
          }
          .num_right {
            font-size: 16px;
            color: #7286a5;
            margin-right: 10px;
          }

          span {
            font-size: 16px;
            color: #7286a5;
            margin-right: 10px;
          }
          .control {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            // border-left: 1px solid #42526a;
            &::before {
              content: '';
              width: 1px;
              height: 36px;
              position: absolute;
              left: 0;
              background: #42526a;
            }
            img {
              width: 11px;
              height: 11px;
              padding: 0 10px;
            }
            .del {
              border-right: 1px solid #42526a;
            }
          }
        }
        /* .num {
          width: 133px;
          height: 30px;
        } */
        .mark {
          padding-left: 10px;
          background: #000000;
        }
        .proportion {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 15px;
          span {
            width: 48%;
          }
          .input {
            margin-top: 0;
          }
        }
        .validate {
          white-space: nowrap; //溢出不换行
          font-size: 13px;
          margin: 10px 0;
        }
        .select-box {
          display: flex;
          margin: 20px 0;
          span {
            width: 40px;
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            border: 1px solid #42526a;
            &:nth-of-type(1) {
              margin-left: 0;
            }
          }
        }
        .btn {
          width: 100%;
          height: 40px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
           user-select: none; 
        }
        .activeItem {
          background: #4f7bb1;
        }
      }
    }
    .data {
      flex: 1;
      padding-right: 15px;
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      .item {
        height: 27px;
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      .price {
        font-size: 18px;
      }
      .imgs {
        display: flex;
        .img {
          border: 1px solid #3a4163;
          padding: 1px;
          margin-right: 5px;
          img {
            width: 15px;
            height: 15px;
          }
        }
        .activeImg {
          border-color: #357ce1;
        }
      }
    }
  }
}
.custom-button {
  width: 30px;
  color: #fff;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  background-color: #357ce1;
  border-radius: 100px;
}
</style>
